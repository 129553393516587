import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tab, Tabs } from "@nextui-org/react";
import CustomerInformation from "./CustomerInformation";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import CustomerLogins from "./CustomerLogins";
import CustomerContacts from "./CustomerContacts";
import Customer12p from "./Customer12p";
import CustomerKpi from "./CustomerKpi";
import CustomerReport from "./CustomerReport";
import { Twelvepoints } from "../../components/Twelvepoints";
import User from "../../components/User";
import CustomerNotes from "./CustomerNotes";
import CustomerServices from "./CustomerServices";
import CustomerEvents from "./CustomerEvents";
import CustomerProjects from "./CustomerProjects";
import { client } from "../../utils/client";
import CustomerActivities from "../Activities/CustomerActivities";
import CustomerHours from "./CustomerHours";

export default function Customer() {
  const { id } = useParams();
  const { customers } = useContext(DataContext);
  const customer = customers.data.find((customer) => customer._id.toString() === id);

  return (
    <div className="relative grid grid-cols-3 gap-5">
      <div className="flex justify-between col-span-3 p-8 panel rounded-xl">
        <div className="flex space-x-5">
          <Avatar
            onClick={async () => {
              await client.service("customers").updateFavicon(customer!._id.toString(), {});
            }}
            name={customer?.name}
            classNames={{
              base: "bg-transparent",
              name: "font-medium",
            }}
            className="cursor-pointer"
            src={customer?.favicon || ""}
            size="lg"
          />

          <div>
            <h1 className="my-auto text-2xl font-medium text-gray">{customer!.name}</h1>
            <div className="text-foreground-400">{customer!.website}</div>
          </div>
        </div>
        <div className="flex my-auto gap-x-10">
          <User _id={customer?.user?.toString()} size="md" />
          <Twelvepoints customer={customer!} size="lg" />
        </div>
      </div>
      <div className="absolute top-0 right-0 ">
        <Dropdown>
          <DropdownTrigger>
            <EllipsisHorizontalIcon className="w-6 h-6 mt-3 mr-4 cursor-pointer text-foreground-400 hover:text-gray" />
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem key="delete" color="default">
              Inaktivera kund
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="w-full col-span-3 space-y-4 lg:col-span-2 h-fit">
        <div className="w-full col-span-3 p-4 panel lg:col-span-2 h-fit ">
          <Tabs aria-label="Customer info" variant="underlined">
            <Tab key="overview" title="Kundlogg">
              <CustomerNotes customer={customer!} />
            </Tab>
            <Tab key="info" title="Kund">
              <CustomerInformation customer={customer!} />
            </Tab>
            <Tab key="logins" title="Inlogg">
              <CustomerLogins customer={customer!} />
            </Tab>
            <Tab key="contacts" title="Kontakter">
              <CustomerContacts customer={customer!} />
            </Tab>
            <Tab key="12p" title="12P">
              <Customer12p customer={customer!} />
            </Tab>
            <Tab key="kpi" title="KPI:er">
              <CustomerKpi customer={customer!} />
            </Tab>
            <Tab key="report" title="Rapport">
              <CustomerReport customer={customer!} />
            </Tab>
          </Tabs>
        </div>
        <div className="w-full col-span-3 p-4 panel lg:col-span-2 h-fit">
          <CustomerActivities customerId={customer!._id.toString()} />
        </div>
      </div>
      <div className="flex flex-col col-span-3 gap-5 lg:col-span-1">
        <CustomerServices customer={customer!} />
        <CustomerProjects customer={customer!} />
        <CustomerEvents customer={customer!} />
        <CustomerHours customer={customer!} />
      </div>
    </div>
  );
}
