import React from "react";
import { Text } from "../utils/client";

type TitleMetaUrlProps = {
  text: Text;
};

export default function TitleMetaUrl({ text }: TitleMetaUrlProps) {
  return (
    <div className="max-w-lg">
      <div className="text-xs text-foreground-600">{text.url}</div>
      <div className="text-sm text-blue-600">{text.title}</div>
      <div className="text-xs text-foreground-600">{text.metadescription}</div>
    </div>
  );
}
