import { useEffect, useState } from "react";
import { Customerservice, Keyword, client } from "../../utils/client";
import {
  Button,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from "@nextui-org/react";
import { useModal } from "../../hooks/useModal";

type ContentFormProps = {
  keywords: Keyword[];
  service: Customerservice;
};
export default function TextOrderForm({ keywords, service }: ContentFormProps) {
  const columns = [{ key: "keyword", label: "SÖKORD" }];
  const [singleText, setSingleText] = useState<boolean>(false);
  const [writeWithAi, setWriteWithAi] = useState<boolean>(false);
  const [wordCount, setWordCount] = useState<string>();

  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await fetch("https://app.surferseo.com/api/v1/locations");
      const json = await locations.json();
    };
    // If single keyword, set singleText to true
    if (keywords.length === 1) {
      setSingleText(true);
    }
  }, []);

  const { closeModal } = useModal();

  const handleOrderContent = async () => {
    try {
      if (singleText) {
        await client.service("texts").create({
          serviceId: service._id.toString(),
          customerId: service.customerId.toString(),
          surfer: {
            writeWithAI: writeWithAi,
            wordCount: wordCount ? Number(wordCount) : undefined,
            keywords: keywords.map((keyword) => keyword._id.toString()),
          },
        });
      } else {
        // Create multiple texts
        for (const keyword of keywords) {
          await client.service("texts").create({
            serviceId: service._id.toString(),
            customerId: service.customerId.toString(),
            surfer: {
              writeWithAI: writeWithAi,
              wordCount: wordCount ? Number(wordCount) : undefined,
              keywords: [keyword._id.toString()],
            },
          });
        }
      }
      closeModal();
    } catch (error) {
      console.error("Error ordering content", error);
    }
  };

  return (
    <div className="space-y-5">
      <Table
        classNames={{
          wrapper: "shadow-none p-0",
        }}
        aria-label="Example table with dynamic content"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody items={keywords}>
          {(item) => (
            <TableRow key={item._id.toString()}>
              {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div>
        <Switch size="sm" isSelected={writeWithAi} onValueChange={setWriteWithAi}>
          Använd AI
        </Switch>
      </div>
      {keywords && keywords.length > 1 && (
        <div>
          <Switch size="sm" isSelected={singleText} onValueChange={setSingleText}>
            Skapa en text med flera sökord
          </Switch>
        </div>
      )}
      <div>
        <Input placeholder="Antal ord" type="number" value={wordCount} onValueChange={setWordCount} />
      </div>
      <div className="flex justify-end space-x-5">
        <Button onClick={handleOrderContent} size="sm" color="primary">
          {singleText ? "Skapa text" : "Skapa texter"}
        </Button>
      </div>
    </div>
  );
}
