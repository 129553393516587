import React, { createContext, useState, useContext, useEffect, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { User, client } from "../utils/client";

type AuthContextType = {
  token: string | null;
  user: User | null;
  onLogin: () => void;
  onLogout: () => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(localStorage.getItem("feathers-jwt"));
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true); // new state variable

  useEffect(() => {
    if (token) {
      client.authentication
        .setAccessToken(token)
        .then(() => client.reAuthenticate())
        .then((response: any) => {
          setUser(response.user);
          setLoading(false);
        })
        .catch(() => {
          setToken(null);
          setUser(null);
          localStorage.removeItem("feathers-jwt");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token, navigate]);

  const handleLogin = async () => {
    const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3030";

    window.location.href = baseURL + "/oauth/google";
  };

  useEffect(() => {
    const hash = window.location.hash;
    const token = new URLSearchParams(hash.replace("#", "?")).get("access_token");

    if (token) {
      client.authentication
        .setAccessToken(token)
        .then(() => client.reAuthenticate())
        .then((response: any) => {
          setToken(token);
          setUser(response.user);
          localStorage.setItem("feathers-jwt", token);
          navigate("/dashboard");
        })
        .catch((err: any) => {
          console.error("Error during authentication", err);
        });
    }
  }, [navigate]);

  const handleLogout = () => {
    client.logout().then(() => {
      setToken(null);
      setUser(null);
      localStorage.removeItem("feathers-jwt");
      navigate("/");
    });
  };

  const value = {
    token,
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{loading ? <div>Authenticating...</div> : children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
