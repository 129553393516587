import React, { useContext, useState } from "react";
import { Select, SelectItem, Avatar, Selection } from "@nextui-org/react";
import { DataContext } from "../../../context/DataContext";
import { useFormikContext } from "formik";

type SelectUserProps = {
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string | {} | undefined | null;
};

export default function SelectUser({ name, label, onChange, onBlur, value }: SelectUserProps) {
  const { users } = useContext(DataContext);
  const { setFieldValue } = useFormikContext();
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set(value ? [String(value)] : []));

  const handleChange = (keys: Selection) => {
    setSelectedKeys(keys);
    const selectedValue = Array.from(keys).pop() as string | null;
    setFieldValue(name, selectedValue);
    onChange({ target: { name, value: selectedValue ?? null } } as React.ChangeEvent<any>);
  };

  return (
    <Select
      name={name}
      items={users.data}
      selectedKeys={selectedKeys}
      onSelectionChange={handleChange}
      onBlur={onBlur}
      label={label}
      fullWidth
      classNames={{
        label: "group-data-[filled=true]:-translate-y-5",
        trigger: "min-h-16",
        listboxWrapper: "max-h-[400px]",
      }}
      listboxProps={{
        itemClasses: {
          base: [
            "rounded-md",
            "text-default-500",
            "transition-opacity",
            "data-[hover=true]:text-foreground",
            "data-[hover=true]:bg-default-100",
            "dark:data-[hover=true]:bg-default-50",
            "data-[selectable=true]:focus:bg-default-50",
            "data-[pressed=true]:opacity-70",
            "data-[focus-visible=true]:ring-default-500",
          ],
        },
      }}
      popoverProps={{
        classNames: {
          base: "before:bg-default-200",
          content: "p-0 border-small border-divider bg-background",
        },
      }}
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.key} className="flex items-center gap-2">
            <Avatar alt={item.data?.fullName} className="flex-shrink-0" size="sm" src={item.data?.profilePicture} />
            <div className="flex flex-col">
              <span>{item.data?.fullName}</span>
              <span className="text-default-500 text-tiny">({item.data?.email})</span>
            </div>
          </div>
        ));
      }}
    >
      {(user) => (
        <SelectItem key={user._id.toString()} textValue={user.fullName}>
          <div className="flex items-center gap-2">
            <Avatar alt={user.fullName} className="flex-shrink-0" size="sm" src={user.profilePicture} />
            <div className="flex flex-col">
              <span className="text-small">{user.fullName}</span>
              <span className="text-tiny text-default-400">{user.email}</span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  );
}
