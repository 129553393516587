type Props = {
  value: number | string;
};

export default function formatCurrency({ value }: Props) {
  return (
    <span>
      {new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK", minimumFractionDigits: 0 }).format(
        Number(value)
      )}
    </span>
  );
}
