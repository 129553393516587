import { Button, Pagination, Textarea } from "@nextui-org/react";
import { Customer, client } from "../../utils/client";
import { useState } from "react";

type Customer12pProps = {
  customer: Customer;
};

export default function Customer12p({ customer }: Customer12pProps) {
  const [relation, setRelation] = useState(customer.twelvepoints?.relation || 1);
  const [delivery, setDelivery] = useState(customer.twelvepoints?.delivery || 1);
  const [price, setPrice] = useState(customer.twelvepoints?.price || 1);
  const [need, setNeed] = useState(customer.twelvepoints?.need || 1);
  const [comment, setComment] = useState(customer.twelvepoints?.comment || "");

  const handleSave = async () => {
    try {
      await client.service("customers").patch(customer._id.toString(), {
        twelvepoints: {
          relation,
          delivery,
          price,
          need,
          comment,
        },
      });
    } catch (error) {
      console.error("Error updating customer", error);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-gray">Relation</h2>
        <Pagination total={5} color="primary" page={relation} onChange={setRelation} />
      </div>
      <div>
        <h2 className="text-gray">Leverans</h2>
        <Pagination total={5} color="primary" page={delivery} onChange={setDelivery} />
      </div>
      <div>
        <h2 className="text-gray">Pris</h2>
        <Pagination total={5} color="primary" page={price} onChange={setPrice} />
      </div>
      <div>
        <h2 className="text-gray">Behov</h2>
        <Pagination total={5} color="primary" page={need} onChange={setNeed} />
      </div>
      <div>
        <h2 className="text-gray">Kommentar</h2>
        <Textarea className="" value={comment} onChange={(e) => setComment(e.target.value)} />
      </div>

      <Button color="primary" onClick={handleSave}>
        Spara
      </Button>
    </div>
  );
}
