import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, User } from "@nextui-org/react";
import { useAuth } from "../context/AuthContext";

type UserMenuProps = {
  size?: "sm" | "md" | "lg";
};

export default function UserMenu({ size = "md" }: UserMenuProps) {
  const { user, onLogout } = useAuth();

  return (
    <Dropdown>
      <DropdownTrigger className="">
        <User
          as="button"
          avatarProps={{
            src: user?.profilePicture || "",
          }}
          className="transition-transform text-gray"
          description={size === "md" ? user?.email : null}
          name={size === "md" ? user?.fullName : null}
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="User Actions" variant="flat">
        <DropdownItem key="logout" color="danger" onClick={onLogout}>
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
