import { createClient } from "api";
import io from "socket.io-client";
import socketio from "@feathersjs/socketio-client";

export * from "api";

export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3030";

const connection = socketio(io(apiUrl));

export const client = createClient(connection);
