import moment from "moment";

type DateSinceProps = {
  date: number;
  size?: "xs" | "sm" | "md" | "lg";
};

export default function DateSince({ date, size }: DateSinceProps) {
  moment.locale("sv");
  return (
    <div title={moment(date).format("YYYY-MM-DD HH:ss:mm")} className={`text-${size} text-foreground-400`}>
      {moment(date).fromNow()}
    </div>
  );
}
