import React, { createContext, useState, ReactNode } from "react";
import { Modal as NextModal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";

type ModalContextType = {
  showModal: (content: ReactNode, title: string) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalTitle, setModalTitle] = useState("");

  const showModal = (content: ReactNode, title: string) => {
    setModalContent(content);
    setModalTitle(title);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent(null);
    setModalTitle("");
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      <NextModal placement="top" size="xl" isOpen={isOpen} onOpenChange={setIsOpen}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-2xl font-medium">{modalTitle}</ModalHeader>
              <ModalBody>{modalContent}</ModalBody>
            </>
          )}
        </ModalContent>
      </NextModal>
    </ModalContext.Provider>
  );
};
