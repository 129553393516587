import { Button, Input } from "@nextui-org/react";
import { Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import * as Yup from "yup"; // Assuming you're using Yup for validation
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { client } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import { ObjectId } from "bson";

type LoginFormProps = {
  customerId: string;
  loginId?: string;
};

const validationSchema = Yup.object().shape({
  type: Yup.string(),
  username: Yup.string(),
  password: Yup.string(),
  url: Yup.string(),
  port: Yup.number(),
});

export default function LoginForm({ customerId, loginId }: LoginFormProps) {
  const { customers } = useContext(DataContext);
  const [isVisible, setIsVisible] = React.useState(false);
  const { closeModal } = useModal();
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [initialValues, setInitialValues] = useState({
    type: "",
    username: "",
    password: "",
    url: "",
    port: 0,
  });

  useEffect(() => {
    if (loginId) {
      // Find the existing login data if loginId is provided
      const customer = customers.data.find((customer) => customer._id === customerId);
      const login = customer?.logins?.find((login) => login._id === loginId);
      if (login) {
        setInitialValues({
          type: login.type || "",
          username: login.username || "",
          password: login.password || "",
          url: login.url || "",
          port: login.port || 0,
        });
      }
    }
  }, [customerId, loginId, customers.data]);

  const onSubmit = async (data: any) => {
    try {
      const customer = customers.data.find((customer) => customer._id === customerId);

      if (!customer) {
        throw new Error("Customer not found");
      }

      let updatedLogins;

      if (loginId) {
        // Update existing login
        updatedLogins = customer?.logins?.map((login) => (login._id === loginId ? { ...login, ...data } : login));
      } else {
        // Create new login
        const newLogin = { _id: new ObjectId(), ...data }; // Create a new login with a new ID
        updatedLogins = [...(customer.logins || []), newLogin];
      }
      await client.service("customers").patch(customerId, { logins: updatedLogins });

      // Close the modal
      closeModal();
    } catch (error) {}
  };

  const onDelete = async () => {
    try {
      const customer = customers.data.find((customer) => customer._id === customerId);

      if (!customer) {
        throw new Error("Customer not found");
      }

      const updatedLogins = customer?.logins?.filter((login) => login._id !== loginId);

      await client.service("customers").patch(customerId, { logins: updatedLogins });

      // Close the modal
      closeModal();
    } catch (error) {}
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ values, handleChange, handleBlur, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Input
              fullWidth
              name="type"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Typ"
              value={values.type}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="username"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Användarnamn"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="password"
              labelPlacement="inside"
              variant="flat"
              label="Lösenord"
              value={values.password}
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <EyeSlashIcon className="w-5 h-5 pointer-events-none text-default-400" />
                  ) : (
                    <EyeIcon className="w-5 h-5 pointer-events-none text-default-400" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="url"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="URL"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="port"
              labelPlacement="inside"
              variant="flat"
              type="number"
              label="Port"
              value={values.port.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="flex justify-end mt-5 space-x-5">
            {!dirty && loginId && (
              <Button onClick={onDelete} type="button" color="danger">
                Ta bort
              </Button>
            )}
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
