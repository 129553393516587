import React, { useState } from "react";
import { client, Task } from "../utils/client";
import { Checkbox } from "@nextui-org/react";

type TaskStatusProps = {
  task: Task;
};

export default function TaskStatus({ task }: TaskStatusProps) {
  const [isSelected, setIsSelected] = useState(task.completed);

  const completeTask = async () => {
    setIsSelected(!isSelected);

    // If no deadline is set, set it to now
    const data = {
      deadline: task.deadline || Date.now(),
      completed: !task.completed,
    };

    await client.service("tasks").patch(task._id.toString(), data);
  };

  return <Checkbox isSelected={isSelected} onValueChange={completeTask} />;
}
