import { DatePicker as NextDatePicker } from "@nextui-org/react";
import { CalendarDate, DateValue, today } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";

// Convert Unix timestamp to DateValue
function timestampToDateValue(timestamp: number): CalendarDate {
  const date = new Date(timestamp);
  return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

// Convert DateValue to Unix timestamp, ensuring the time is set to noon to avoid timezone issues
function dateValueToTimestamp(date: DateValue): number {
  return new Date(date.year, date.month - 1, date.day, 12).getTime(); // Setting time to noon to avoid timezone discrepancies
}

type DatePickerProps = {
  value: number | null | undefined; // Unix timestamp or null/undefined for no deadline
  onChange: (value: number | null) => void; // Callback with Unix timestamp or null
  label: string;
};

export default function DatePicker({ value, onChange, label }: DatePickerProps) {
  // Convert the incoming Unix timestamp to DateValue, or use null for no deadline
  const dateValue = value ? timestampToDateValue(value) : null;

  return (
    <I18nProvider locale="sv-SE">
      <NextDatePicker
        label={label}
        granularity="day"
        value={dateValue} // Pass null if there's no deadline
        onChange={(date) => {
          const newTimestamp = date ? dateValueToTimestamp(date) : null;
          onChange(newTimestamp); // Pass null if no date is selected
        }}
      />
    </I18nProvider>
  );
}
