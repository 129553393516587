import { Formik, Form } from "formik";
import { Customer, client } from "../../utils/client";
import { Button, Input } from "@nextui-org/react";
import SelectUser from "../../components/Forms/Fields/SelectUser";
import { useModal } from "../../hooks/useModal";

export default function CustomerForm() {
  const { closeModal } = useModal();
  const onSubmit = async (data: Customer) => {
    await client.service("customers").create(data);
    closeModal();
  };

  return (
    <Formik
      initialValues={
        {
          name: "",
          active: true,
          website: "",
        } as Customer
      }
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, dirty }) => (
        <Form>
          <div className="space-y-2">
            <Input
              fullWidth
              name="name"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Namn"
              value={values.name || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="website"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Hemsida"
              value={values.website || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="orgNumber"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Organisationsnummer"
              value={values.orgNumber || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="my-2">
            <SelectUser
              name="user"
              label="Affärsansvarig"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.user}
            />
          </div>

          <div className="flex justify-end mt-5">
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button" className="ml-2">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
