import { useState } from "react";
import { Customer, client } from "../../utils/client";
import { Input, Button, Textarea, Link, LinkIcon } from "@nextui-org/react";
import { Formik, Form } from "formik";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import SelectUser from "../../components/Forms/Fields/SelectUser";

type CustomerInformationProps = {
  customer: Customer;
};

export default function CustomerInformation({ customer }: CustomerInformationProps) {
  const [isReadOnly, setIsReadOnly] = useState(true);

  const onSubmit = async (data: Customer) => {
    if (data.user === "") {
      data.user = undefined;
    }
    await client.service("customers").patch(customer._id.toString(), data);

    setIsReadOnly(true);
  };

  const handleEditToggle = () => {
    setIsReadOnly(!isReadOnly);
  };

  return (
    <div className="relative">
      <Formik initialValues={customer} onSubmit={onSubmit}>
        {({ values, handleChange, handleBlur, dirty }) => (
          <Form>
            <div className="space-y-2">
              {!isReadOnly && (
                <>
                  <Input
                    fullWidth
                    name="name"
                    labelPlacement="inside"
                    variant="flat"
                    type="text"
                    label="Namn"
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    fullWidth
                    name="website"
                    labelPlacement="inside"
                    variant="flat"
                    type="text"
                    label="Hemsida"
                    value={values.website || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </>
              )}
              <Input
                fullWidth
                name="orgNumber"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Organisationsnummer"
                value={values.orgNumber || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                name="address"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Adress"
                value={values.address || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                name="postcode"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Postkod"
                value={values.postcode || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                name="city"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Stad"
                value={values.city || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                name="invoiceEmail"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Faktura mail"
                value={values.invoiceEmail || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                endContent={
                  values.actionPlanUrl && (
                    <Link className="my-auto" href={values.actionPlanUrl} target="_blank">
                      <LinkIcon />
                    </Link>
                  )
                }
                name="actionPlanUrl"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Action plan"
                value={values.actionPlanUrl || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                fullWidth
                name="timebank"
                isReadOnly={isReadOnly}
                labelPlacement="inside"
                variant="flat"
                type="number"
                label="Timbank"
                value={values.timebank?.toString() || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {!isReadOnly && (
              <div className="my-2">
                <SelectUser
                  name="user"
                  label="Affärsansvarig"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user}
                />
              </div>
            )}
            {!isReadOnly && (
              <div className="flex justify-end mt-5">
                {dirty && (
                  <Button type="submit" color="primary">
                    Spara
                  </Button>
                )}
                <Button type="button" onClick={handleEditToggle} className="ml-2">
                  Avbryt
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="absolute top-0 right-0 -mt-10">
        <PencilSquareIcon
          onClick={handleEditToggle}
          className="w-5 h-5 transition cursor-pointer text-foreground-400 hover:text-gray"
        />
      </div>
    </div>
  );
}
