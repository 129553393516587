import React from "react";

type Props = {
  width?: number;
  height?: number;
  className?: string;
};

export default function Icon(props: Props) {
  return (
    <svg viewBox="0 0 52 52" fill="none" className={props.className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.5443 40.2773C24.7307 41.0088 20.8701 40.2301 17.6685 38.0355C14.467 35.8645 12.2777 32.5608 11.548 28.7379C10.8182 24.915 11.6186 21.045 13.7843 17.8357C15.9501 14.6264 19.2458 12.4553 23.0593 11.7002C26.8729 10.9687 30.7336 11.7474 33.9351 13.942C40.5735 18.4492 42.3155 27.5108 37.8193 34.1418C35.63 37.3747 32.3579 39.5457 28.5443 40.2773ZM23.6949 15.1455C20.7994 15.7119 18.2806 17.3637 16.6327 19.7943C14.9849 22.2485 14.3728 25.1982 14.9378 28.1008C15.5028 31.0033 17.1506 33.5047 19.5989 35.1801C22.0471 36.832 24.9661 37.4455 27.8852 36.8792C30.7807 36.3128 33.2995 34.661 34.9473 32.2304C36.5952 29.7762 37.2072 26.8501 36.6423 23.9239C36.0773 21.0214 34.4294 18.4964 32.0048 16.8446C29.533 15.1927 26.5904 14.5792 23.6949 15.1455Z"
        fill="currentColor"
      ></path>
      <path
        d="M13.2204 50.8961C11.9257 51.1557 10.5838 50.8725 9.50097 50.1409C7.73543 48.9374 6.93505 46.7664 7.50002 44.6898L7.59419 44.3595L7.35878 44.1235C-1.21 35.3687 -2.43411 21.6347 4.41621 11.4876C8.27687 5.7769 14.1385 1.90684 20.9182 0.608957C24.4728 -0.075382 28.1451 -0.00458756 31.6527 0.797741C30.9935 1.78885 30.5227 2.87436 30.2638 4.05425C27.3918 3.4643 24.4728 3.4643 21.5773 4.03065C15.7157 5.16335 10.6545 8.51425 7.3117 13.4462C1.49717 22.0594 2.43879 33.7876 9.54805 41.3625L9.83055 41.6693L10.2072 41.4805C10.5838 41.3153 10.9605 41.1737 11.3607 41.1029C12.6554 40.8434 13.9972 41.1265 15.0801 41.8581C17.3635 43.3919 17.9521 46.5069 16.4219 48.7723C15.6686 49.905 14.5387 50.6365 13.2204 50.8961ZM11.7373 43.1088C10.9605 43.2504 10.3014 43.6987 9.87762 44.3359C9.43035 44.973 9.28911 45.7753 9.43035 46.5305C9.5716 47.3092 10.0189 47.9699 10.6545 48.3947C11.2901 48.8431 12.0904 48.9846 12.8437 48.8431C13.6206 48.7015 14.2797 48.2531 14.7034 47.616C15.598 46.2709 15.2449 44.4538 13.9266 43.5335C13.291 43.1324 12.4906 42.9672 11.7373 43.1088Z"
        fill="currentColor"
      ></path>
      <path
        d="M30.6891 51.3912C27.1345 52.0755 23.4621 52.0047 19.9546 51.2024C20.6137 50.2113 21.0845 49.1258 21.3435 47.9459C24.2154 48.5358 27.1345 48.5358 30.03 47.9695C35.8916 46.8368 40.9528 43.4859 44.2956 38.5539C50.1101 29.9407 49.1685 18.2125 42.0592 10.6376L41.7767 10.3308L41.4001 10.5196C41.0234 10.6848 40.6468 10.8264 40.2466 10.8972C38.9519 11.1568 37.61 10.8736 36.5272 10.142C35.4208 9.38691 34.691 8.25421 34.4321 6.95633C34.1731 5.65844 34.4556 4.31336 35.1854 3.22786C35.9387 2.11876 37.0686 1.38722 38.3633 1.12765C39.6581 0.868068 40.9999 1.15124 42.0828 1.88278C43.8483 3.06267 44.6487 5.25728 44.0837 7.31029L43.9895 7.64067L44.2249 7.87665C52.7937 16.6315 54.0178 30.3654 47.1675 40.5125C43.3304 46.2232 37.4688 50.0933 30.6891 51.3912ZM38.74 3.13347C37.9631 3.27505 37.304 3.72341 36.8803 4.36056C35.9857 5.70564 36.3388 7.52268 37.6571 8.44299C38.2927 8.89135 39.0931 9.03294 39.8464 8.89136C40.6232 8.74977 41.2824 8.30141 41.7061 7.66426C42.1534 7.02712 42.2946 6.22479 42.1534 5.46966C42.0121 4.69093 41.5649 4.03019 40.9293 3.60542C40.2937 3.13347 39.5168 2.96828 38.74 3.13347Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
