import { client, Task } from "../../utils/client";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { useRenderCell } from "../../hooks/useRenderCell";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";

export default function MyActivities() {
  const { user } = useAuth();
  const { tasks } = useContext(DataContext);
  const [myTasks, setMyTasks] = useState<Task[]>([]);

  useEffect(() => {
    const fetchMyTasks = async () => {
      const myTasks = await client.service("tasks").find({
        query: {
          assignedTo: user!._id.toString(),
          completed: false,
        },
      });

      // Sort myTasks by deadline if deadline is defined else put without deadline last
      myTasks.data.sort((a, b) => {
        const deadlineA = a.deadline ? a.deadline.valueOf() : Number.MAX_SAFE_INTEGER;
        const deadlineB = b.deadline ? b.deadline.valueOf() : Number.MAX_SAFE_INTEGER;
        return deadlineA - deadlineB;
      });

      setMyTasks(myTasks.data);
    };
    fetchMyTasks();
  }, [tasks]);

  const renderCell = useRenderCell();
  const columns = [
    { key: "taskStatus", label: "" },
    { key: "customerId", label: "KUND" },
    { key: "text", label: "AKTIVITET" },
    { key: "deadline", label: "DEADLINE" },
    { key: "assignedTo", label: "TILLDELAD TILL" },
    { key: "taskActions", label: "" },
  ];
  return (
    <div>
      <Table
        topContent={<div className="p-2 my-2 text-xl font-medium text-gray">Mina Aktiviteter</div>}
        classNames={{
          wrapper: "shadow-none",
        }}
        className="panel"
        aria-label="Controlled table example with dynamic content"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent={"Inga aktiviteter"} items={myTasks}>
          {(task) => (
            <TableRow key={task._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(task, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
