import React from "react";

type TextStatusProps = {
  status: number;
};

export default function TextStatus({ status }: TextStatusProps) {
  switch (status) {
    case 1:
      return <div>Beställd</div>;
    case 2:
      return <div>Mottagen</div>;
    case 3:
      return <div>Text klar</div>;
    case 4:
      return <div>Skickad till kund för korrektur</div>;
    case 5:
      return <div>Klar för publicering</div>;
    case 6:
      return <div>Publicerad</div>;
  }
}
