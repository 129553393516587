import React, { useContext } from "react";
import { Legend, Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { DataContext } from "../../context/DataContext";

export default function ServicesPieChart() {
  const { services } = useContext(DataContext);

  // Fetch all services and count them by type addin them to the data array with the name and value
  const data = services.data.reduce((acc: any, service: any) => {
    const index = acc.findIndex((item: any) => item.name === service.type);
    if (index === -1) {
      acc.push({ name: service.type, value: 1 });
    } else {
      acc[index].value += 1;
    }
    return acc;
  }, []);

  return (
    <div className="w-full h-auto p-6 rounded-lg panel">
      <div className="my-2">
        <div className="text-xl font-medium text-gray">Tjänster</div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie dataKey="value" data={data} cx="50%" cy="50%" outerRadius={80} fill="#8884d8">
              {data.map((entry: any, index: number) => (
                <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function getColor(type: string) {
  const colors: { [key: string]: string } = {
    seo: "#D5A438",
    ads: "#51513D",
    backlinks: "#84596B",
    cro: "#AF9BB6",
    // Add more types and colors as needed
  };

  return colors[type] || "#d5a438"; // Default color
}
