import { Avatar, User } from "@nextui-org/react";
import { Customerservice } from "../utils/client";
import moment from "moment";

type ServiceAvatarProps = {
  service: Customerservice;
  size?: "sm" | "md" | "lg";
};
export default function ServiceAvatar({ service, size = "md" }: ServiceAvatarProps) {
  switch (service.type) {
    case "seo":
      return (
        <User
          classNames={{ base: "bg-transparent" }}
          name="new customers SEO"
          description={moment(service.createdAt).format("YYYY-MM-DD")}
          avatarProps={{ src: "/logo-google.svg", size: size, classNames: { base: "bg-transparent" } }}
        />
      );
    case "ads":
      return (
        <User
          classNames={{ base: "bg-transparent" }}
          name="new customers SEM"
          description={moment(service.createdAt).format("YYYY-MM-DD")}
          avatarProps={{ src: "/logo-google-ads.svg", size: size, classNames: { base: "bg-transparent" } }}
        />
      );
    case "hosting":
      return (
        <User
          classNames={{ base: "bg-transparent" }}
          name="new customers Hosting"
          description={moment(service.createdAt).format("YYYY-MM-DD")}
          avatarProps={{ src: "/logo-hosting.svg", size: size, classNames: { base: "bg-transparent" } }}
        />
      );
    default:
      return (
        <User
          classNames={{ base: "bg-transparent" }}
          name={"new customers " + service.type.toUpperCase()}
          description={moment(service.createdAt).format("YYYY-MM-DD")}
          avatarProps={{ name: service.type.toUpperCase(), size: size, color: "primary", classNames: { base: "" } }}
        />
      );
  }
}
