import { UserPlusIcon } from "@heroicons/react/24/outline";
import { Customer } from "../../utils/client";
import ContactForm from "../../components/Forms/ContactForm";
import { useModal } from "../../hooks/useModal";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue } from "@nextui-org/react";
import { useRenderCell } from "../../hooks/useRenderCell";

type CustomerContactsProps = {
  customer: Customer;
};

export default function CustomerContacts({ customer }: CustomerContactsProps) {
  const { showModal } = useModal();

  const renderCell = useRenderCell();

  const columns = [
    { key: "contact", label: "Contact" },
    { key: "email", label: "E-post" },
    { key: "phone", label: "Telefon" },
    { key: "role", label: "Roll" },
  ];

  return (
    <div className="relative">
      <Table
        classNames={{
          wrapper: "shadow-none p-0",
        }}
        aria-label="Kontakter"
        onRowAction={(key) =>
          showModal(<ContactForm customerId={customer._id.toString()} contactId={key.toString()} />, "Redigera kontakt")
        }
        selectionMode="single"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>

        <TableBody items={customer.contacts ?? []} emptyContent={"Inga kontakter tillagda"}>
          {(item) => (
            <TableRow className="cursor-pointer" key={item._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="absolute top-0 right-0 -mt-10">
        <UserPlusIcon
          onClick={() => showModal(<ContactForm customerId={customer._id.toString()} />, "Skapa kontakt")}
          className="w-5 h-5 transition cursor-pointer text-foreground-400 hover:text-gray"
        />
      </div>
    </div>
  );
}
