import { Customer } from "../utils/client";

type CalculateTwelvepointsProps = {
  customer: Customer;
  size?: "sm" | "lg";
};

export const Twelvepoints = ({ customer, size = "sm" }: CalculateTwelvepointsProps) => {
  if (!customer) return;
  const twelvepoints = customer.twelvepoints;
  if (!twelvepoints) return <span className="text-xs text-slate-400">-</span>;
  const value = twelvepoints.relation + twelvepoints.delivery + twelvepoints.price + twelvepoints.need;

  function textSize() {
    switch (size) {
      case "sm":
        return "text-lg";
      case "lg":
        return "text-4xl";
      default:
        return "text-sm";
    }
  }

  return (
    <div className="flex justify-center whitespace-nowrap ">
      <span className={"my-auto " + textSize()}>{value}</span>
      <span className="my-auto text-xs text-slate-400"> /20</span>
    </div>
  );
};
