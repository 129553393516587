import { Customer } from "../../utils/client";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, getKeyValue } from "@nextui-org/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useModal } from "../../hooks/useModal";
import LoginForm from "../../components/Forms/LoginForm";
import { useRenderCell } from "../../hooks/useRenderCell";

type CustomerLoginsProps = {
  customer: Customer;
};

export default function CustomerLogins({ customer }: CustomerLoginsProps) {
  const { showModal } = useModal();

  const renderCell = useRenderCell();

  const columns = [
    { key: "type", label: "Typ" },
    { key: "username", label: "Användare" },
    { key: "password", label: "Lösenord" },
    { key: "url", label: "URL" },
    { key: "port", label: "Port" },
  ];

  return (
    <div className="relative">
      <Table
        classNames={{
          wrapper: "shadow-none p-0",
        }}
        aria-label="Inlogg"
        onRowAction={(key) =>
          showModal(<LoginForm customerId={customer._id.toString()} loginId={key.toString()} />, "Redigera inlogg")
        }
        selectionMode="single"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>

        <TableBody items={customer.logins ?? []} emptyContent={"Inga inlogg tillagda"}>
          {(item) => (
            <TableRow className="cursor-pointer" key={item._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="absolute top-0 right-0 -mt-10">
        <PlusIcon
          onClick={() => showModal(<LoginForm customerId={customer._id.toString()} />, "Skapa inlogg")}
          className="w-5 h-5 transition cursor-pointer text-foreground-400 hover:text-gray"
        />
      </div>
    </div>
  );
}
