import { useContext } from "react";
import Table from "../../components/Table";
import { Button } from "@nextui-org/react";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { Twelvepoints } from "../../components/Twelvepoints";
import { useModal } from "../../hooks/useModal";
import CustomerForm from "./CustomerForm";
import moment from "moment";

export default function Customers() {
  const { customers } = useContext(DataContext);
  const navigate = useNavigate();
  const { showModal } = useModal();

  console.log(customers);

  const handleRowAction = (customer: any) => {
    navigate(`/customers/${customer.customer._id.toString()}`); // Assuming 'slug' is a property of customer
  };

  const CustomerListSorted = customers.data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const monthName = moment().format("MMMM");

  return (
    <>
      <div className="flex justify-between mb-10">
        <h1 className="text-2xl font-medium text-gray">Kunder</h1>
        <Button color="primary" onClick={() => showModal(<CustomerForm />, "Skapa kund")}>
          Ny kund
        </Button>
      </div>
      <Table
        onRowAction={handleRowAction}
        emptyContent="Inga kunder att visa."
        columns={[
          { key: "customer", label: "Kund" },
          { key: "activities", label: "Timmar" + " i " + monthName },
          { key: "timebank", label: "Timbank" },
          { key: "12p", label: "12p" },
          { key: "value", label: "Affärsvärde" },
          { key: "user", label: "Affärsansvarig" },
          { key: "createdAt", label: "Skapad" },
        ]}
        rows={CustomerListSorted.filter((customer) => customer.active).map((customer) => ({
          key: customer._id.toString(), // Cast the key to string
          timebank: customer.timebank,
          activities: customer.activities,
          value: customer.value,
          customer: customer, // Passing the entire customer object
          user: customer.user,
          createdAt: customer.createdAt,
          "12p": <Twelvepoints customer={customer} />,
        }))}
      />
    </>
  );
}
