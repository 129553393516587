import React, { useContext, useEffect, useState } from "react";
import { User as NextUser } from "@nextui-org/react";
import { DataContext } from "../context/DataContext";
import { User as UserSchema } from "../utils/client";

type UserProps = {
  _id?: string;
  size?: "sm" | "md" | "lg";
};

export default function User({ _id, size = "md" }: UserProps) {
  const { users } = useContext(DataContext);
  const [currentUser, setCurrentUser] = useState<UserSchema | undefined>(
    users.data.find((user) => user._id.toString() === _id)
  );
  useEffect(() => {
    setCurrentUser(users.data.find((user) => user._id.toString() === _id));
  }, [_id, users.data]);
  return (
    <>
      {currentUser && (
        <NextUser
          className="bg-transparent"
          avatarProps={{
            size: size,
            radius: "full",
            src: currentUser.profilePicture,
            classNames: {
              base: "bg-transparent",
            },
          }}
          description={currentUser.email}
          name={currentUser.fullName}
        />
      )}
    </>
  );
}
