import React from "react";
import {
  Tooltip,
  Legend,
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Stats } from "../../utils/client";

type RevenueChartProps = {
  stats: Stats[];
};

export default function RevenueChart({ stats }: RevenueChartProps) {
  console.log(stats);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border custom-tooltip rounded-xl border-grayLight">
          <p style={{ color: payload[0].fill }} className="label">{`${payload[0].dataKey} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-auto p-6 rounded-lg panel">
      <div className="">
        <div className="text-xl font-medium text-gray">Månatlig intäkt</div>
        <div className="text-xs text-default-500">Per tjänst</div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={stats}>
            <Tooltip content={<CustomTooltip />} />

            <Bar
              radius={[5, 5, 0, 0]}
              type="natural"
              barSize={15}
              key={"revenue"}
              stackId="a"
              dataKey={"revenue"}
              fill={"#D5A438"}
            />

            <XAxis
              padding={{ left: 20, right: 20 }}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
              dataKey="_id"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function getColor(type: string) {
  const colors: { [key: string]: string } = {
    seo: "#D5A438",
    ads: "#51513D",
    backlinks: "#84596B",
    cro: "#AF9BB6",
    totalRevenue: "#D5A438",
    // Add more types and colors as needed
  };

  return colors[type] || "#d5a438"; // Default color
}
