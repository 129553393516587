import { useContext, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import Logo from "../components/Logo";
import StatsPanel from "../components/StatsPanel";
import {
  BriefcaseIcon,
  ClipboardDocumentCheckIcon,
  CubeIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import ActivityChart from "./Dashboard/ActivityChart";
import RevenueChart from "./Dashboard/RevenueChart";
import { client, Stats } from "../utils/client";
import NotesChart from "./Dashboard/NotesChart";
import ServicesPieChart from "./Dashboard/ServicesPieChart";
import MyActivities from "./Activities/MyActivities";

export default function Dashboard() {
  const { tasks, customers } = useContext(DataContext);
  const [stats, setStats] = useState<Stats[]>([]);
  const [tasksCount, setTasksCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = await client.service("tasks").find({
        query: { completed: false },
      });
      setTasksCount(tasks.total);
    };

    const fetchCustomers = async () => {
      const customers = await client.service("customers").find({
        query: {
          active: true,
        },
      });
      setCustomersCount(customers.total);
    };

    fetchTasks();
    fetchCustomers();
  }, [tasks, customers]);

  useEffect(() => {
    const fetchStats = async () => {
      const aggregateStats = await client.service("stats").getStatsAggregatePerMonth(
        {
          startDate: "2023-09-01",
          endDate: "2024-08-31",
        },
        {}
      );
      setStats(aggregateStats);
    };
    fetchStats();
  }, []);

  return (
    <div className="">
      <div className="flex justify-between mb-10">
        <h1 className="text-2xl font-medium text-gray">Dashboard</h1>
      </div>
      <div className="flex flex-col justify-center flex-1 min-h-full">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <StatsPanel icon={<BriefcaseIcon />} title="Antal aktiva kunder" value={customersCount} />
          <StatsPanel icon={<CubeIcon />} title="Antal aktiva tjänster" value="46" />
          <StatsPanel icon={<MagnifyingGlassCircleIcon />} title="SEO genomsnittlig position" value="44,98" />
          <StatsPanel
            icon={<ClipboardDocumentCheckIcon />}
            title="Antal öppna 
          aktiviteter"
            value={tasksCount}
          />
          <div className="col-span-2">
            <ActivityChart />
          </div>
          <div className="col-span-2">
            <RevenueChart stats={stats} />
          </div>
          <div className="col-span-2">
            <MyActivities />
          </div>
          <div className="col-span-1">
            <NotesChart stats={stats} />
          </div>
          <div className="col-span-1">
            <ServicesPieChart />
          </div>
        </div>
      </div>
    </div>
  );
}
