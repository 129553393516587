import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { Avatar, Badge, Button, Switch, Textarea } from "@nextui-org/react";
import { Customer, Note, User, client } from "../../utils/client";
import { DataContext } from "../../context/DataContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAuth } from "../../context/AuthContext";
import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

type CustomerNotesProps = {
  customer: Customer;
};

export default function CustomerNotes({ customer }: CustomerNotesProps) {
  const { user } = useAuth();
  const { users } = useContext(DataContext);
  const [notes, setNotes] = useState<Note[]>([]);
  const activityContainerRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<ReactQuill>(null);

  const scrollToBottom = () => {
    const container = activityContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  const fetchNotes = async () => {
    const notes = await client.service("notes").find({
      query: { customerId: customer._id },
      paginate: {
        default: 10000,
        max: 10000,
      },
    });
    setNotes(notes.data);
  };

  useEffect(() => {
    fetchNotes();
  }, [customer._id]);

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  const getUserFromNote = (note: Note) => {
    const user = users.data.find((user) => user._id.toString() === note.userId.toString());
    return user;
  };

  const formik = useFormik({
    initialValues: { text: "", public: false, customerId: customer._id, userId: user!._id.toString() },
    onSubmit: async (values) => {
      await client.service("notes").create(values);
      formik.resetForm();
      fetchNotes(); // Fetch notes again after a new note is submitted
    },
  });

  const handleChange = (value: string) => {
    formik.setFieldValue("text", value);
  };

  return (
    <div className="">
      <div
        ref={activityContainerRef}
        className="p-2 my-4 space-y-4 max-h-[27rem] overflow-y-auto border-t border-foreground-100"
      >
        {notes &&
          notes.map((note, index) => (
            <div key={note._id.toString()} className="flex">
              <div className="relative">
                {index !== notes.length - 1 ? (
                  <span className="absolute top-5 left-5 h-full w-0.5 bg-foreground-100 " aria-hidden="true" />
                ) : null}

                <Badge
                  isOneChar
                  isInvisible={!note.public}
                  color="primary"
                  content={<EnvelopeIcon className="w-3 h-3 text-white" />}
                  placement="bottom-right"
                >
                  <Avatar name={getUserFromNote(note)?.fullName} src={getUserFromNote(note)?.profilePicture} />
                </Badge>
              </div>
              <div className="flex-1 min-w-0 ml-4">
                <div>
                  <div className="text-sm font-medium text-gray">{getUserFromNote(note)?.fullName}</div>
                  <div
                    title={moment(note.createdAt).format("YYYY-MM-DD HH:ss:mm")}
                    className="text-foreground-500 text-small "
                  >
                    {moment(note.createdAt).fromNow()}
                  </div>
                </div>
                <div className="text-sm note text-gray">
                  <div className="break-words " dangerouslySetInnerHTML={{ __html: note.text }}></div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full px-2 py-5 rounded-lg bg-background">
          <div className="flex">
            <Avatar className="mr-3 shrink-0" size="md" name={user?.fullName} src={user?.profilePicture} />
            <div className="w-full mr-4">
              <ReactQuill
                ref={quillRef}
                theme={"snow"}
                className="h-auto bg-white"
                value={formik.values.text}
                onChange={handleChange}
              />
            </div>
          </div>
          {formik.dirty && (
            <div className="flex justify-between gap-2 mt-4 ml-12 mr-4">
              <Switch name="public" onChange={formik.handleChange}>
                <div className="text-sm">Notifiera kund</div>
              </Switch>
              <Button disabled={!formik.dirty} className="disabled:hidden" onClick={formik.submitForm} color="primary">
                {formik.dirty ? "Spara" : "Spara"}
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
