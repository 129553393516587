import { useEffect, useState } from "react";
import { Customer, Event, client } from "../../utils/client";
import DateSince from "../../components/DateSince";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";

type CustomerEventsProps = {
  customer: Customer;
};

export default function CustomerEvents({ customer }: CustomerEventsProps) {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    // Fetch customer events
    const fetchEvents = async () => {
      const events = await client.service("events").find({ query: { customerId: customer._id.toString() } });
      setEvents(events.data);
    };
    fetchEvents();
  }, [customer]);

  const columns = [
    { name: "HÄNDELSER", uid: "service" },
    { name: "", uid: "usericon" },
  ];

  return (
    <Table
      className="panel"
      classNames={{
        wrapper: "shadow-none",
      }}
      selectionMode="none"
      aria-label="Customer services"
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.uid}>{column.name}</TableColumn>}
      </TableHeader>
      <TableBody emptyContent={"Inga händelser"} items={events}>
        {(event) => (
          <TableRow key={event._id.toString()}>
            <TableCell>
              <div className="text-gray">{event.type}</div>
            </TableCell>
            <TableCell>
              <DateSince date={event.createdAt!} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
