import { Table as NextTable, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useRenderCell } from "../hooks/useRenderCell";

type TableProps = {
  onRowAction?: (row: any) => void;
  emptyContent?: string;
  columns: {
    key: string;
    label: string;
    allowsSorting?: boolean;
  }[];
  rows: {
    key: string;
    [key: string]: any; // Allow any type for values
  }[];
};

export default function Table({ columns, rows, emptyContent = "No rows to display.", onRowAction }: TableProps) {
  const renderCell = useRenderCell();

  return (
    <NextTable
      className="panel"
      classNames={{
        wrapper: "shadow-none",
      }}
      aria-label="Dynamic table"
      selectionMode="single"
      selectionBehavior={"toggle"}
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody emptyContent={emptyContent} items={rows}>
        {(item) => (
          <TableRow onClick={() => onRowAction && onRowAction(item)} className="cursor-pointer" key={item.key}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey.toString())}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </NextTable>
  );
}
