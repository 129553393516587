import Logo from "../components/Logo";
import SignInWithGoogleButton from "../components/SignInWithGoogleButton";

export default function Login() {
  return (
    <div className="h-screen">
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <Logo className="text-gray" />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div className="flex justify-center">
              <SignInWithGoogleButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
