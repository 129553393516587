export default function NoMatch() {
  return (
    <div className="h-full">
      <div className="flex flex-col justify-center flex-1 min-h-full px-6 py-12 lg:px-8">
        <div className="">
          <div className="space-y-6">
            <div className="flex justify-center text-6xl text-gray">404 - Not found</div>
          </div>
        </div>
      </div>
    </div>
  );
}
